import React from 'react';

function BaseComponent({props, style, children, className, primaryClassName}) {
  const classes = [primaryClassName];

  if (className) {
    classes.push(className);
  }

  return (
    <div style={style} className={classes.join(' ')}>
      {children}
    </div>
  )
}

export default BaseComponent